<template>
  <div :class="theme">
    <div id="scanner-container">
      <!-- Hardware Scanner Input -->
      <div class="scanner-input">
        <input
          ref="scannerInput"
          type="text"
          v-model="hardwareScanValue"
          placeholder="Scanner"
          @change="onHardwareScan"
          @focus="setActiveInput('hardware')"
        />
      </div>

      <!-- Camera Scanner -->
      <div id="camera-container">
        <div v-if="scanner_active" id="containerStream">
          <QrStream @decode="onCameraScan"/>
          <button-close-stream @click="closeScanner"/>
        </div>
        
        <transition name="scan">
          <div id="badge" v-if="!scanner_active" @click="startScanner">
            <p>{{ data }}</p>
          </div>
        </transition>
      </div>
    </div>

    <companion-dialog
      :show="showCompanionDialog"
      @confirm="handleCompanionConfirm"
      @cancel="handleCompanionCancel"
    />
  </div>
</template>
 
<script>
import { curr_theme } from '@/db/session_db';
import { QrStream } from 'vue3-qr-reader';
import { reactive, toRefs } from 'vue';
import ButtonCloseStream from './ButtonCloseStream.vue';
import CompanionDialog from './CompanionDialog.vue';
import api from '@/services/axios';
import scannerSound from '@/assets/scanner_sound.mp3';
import Cookies from "js-cookie";

export default {
  name: 'DualInputScanner',
 
  props: {
    workshopId: {
      type: [String, Number],
      required: true,
      default: process.env.VUE_APP_WORKSHOP_ID
    }
  },

  components: {
    QrStream,
    ButtonCloseStream,
    CompanionDialog
  },

  data() {
    return {
      theme: curr_theme,
      scanner_active: false,
      showCompanionDialog: false,
      tempEncodedData: null,
      hardwareScanValue: '',
      activeInput: null // 'hardware' or 'camera'
    }
  },

  setup() {
    const state = reactive({
      data: "Scan QR"
    })
    return {
      ...toRefs(state),
    }
  },

  mounted() {
    // Focus the hardware scanner input on component mount
    this.$refs.scannerInput.focus();
  },

  methods: {
    setActiveInput(inputType) {
      this.activeInput = inputType;
    },

    // Handle hardware scanner input
    async onHardwareScan() {
      if (this.hardwareScanValue) {
        const audio = new Audio(scannerSound);
        audio.play();
        
        this.tempEncodedData = this.hardwareScanValue;
        this.showCompanionDialog = true;
        
        // Clear the input for next scan
        this.hardwareScanValue = '';
        // Refocus the input for next scan
        this.$nextTick(() => {
          this.$refs.scannerInput.focus();
        });
      }
    },

    // Handle camera scan
    async onCameraScan(encodedData) {
      const audio = new Audio(scannerSound);
      audio.play();
      
      this.tempEncodedData = encodedData;
      this.showCompanionDialog = true;
    },

    async handleCompanionConfirm(companions) {
      try {            
        const url = 'attended_confirm_ticket/';          
       
        const payload = {
          workshop_id: this.workshopId,
          encoded: this.tempEncodedData,
          companions: companions
        };
       
        const response = await api.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('token')
          }
        });
       
        this.$emit("gotNewScan", response.data);
        this.theme = response.data.status == 200 ? "theme-green" : "theme-red";
        this.data = "Scan Next QR";
        
        // Refocus hardware scanner input after successful scan
        if (this.activeInput === 'hardware') {
          this.$nextTick(() => {
            this.$refs.scannerInput.focus();
          });
        }
       
      } catch (error) {
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        this.theme = "theme-red";
       
        let errorMessage = "فشل في معالجة رمز QR";
        if (error.response) {
          errorMessage = error.response.data?.message || "خطأ في الخادم";
        } else if (error.request) {
          errorMessage = "فشل الاتصال بالخادم";
        }
        this.$emit("scanError", errorMessage);
      } finally {
        this.showCompanionDialog = false;
        this.tempEncodedData = null;
      }
    },

    handleCompanionCancel() {
      this.showCompanionDialog = false;
      this.tempEncodedData = null;
      this.theme = curr_theme;
      this.data = "Scan QR";
      
      // Refocus hardware scanner input after cancel
      if (this.activeInput === 'hardware') {
        this.$nextTick(() => {
          this.$refs.scannerInput.focus();
        });
      }
    },

    startScanner() {
      this.scanner_active = true;
      this.setActiveInput('camera');
      this.$emit('newScan');
    },

    closeScanner() {
      this.scanner_active = false;
      this.showCompanionDialog = false;
      this.tempEncodedData = null;
      this.theme = curr_theme;
      this.data = "Scan QR";
      this.$emit('scanClosed');
      
      // Return focus to hardware scanner input
      this.$nextTick(() => {
        this.$refs.scannerInput.focus();
      });
    }
  }
}
</script>

<style scoped>
#scanner-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.scanner-input {
  width: 100%;
  padding: 10px;
}

.scanner-input input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--main-color);
  border-radius: 8px;
  background: var(--background-color);
  color: var(--main-color);
}

#camera-container {
  position: relative;
  background: var(--striped-background);
  height: 80vw;
  border-radius: 15px;
  max-width: 400px;
  max-height: 400px;
  padding: 10px;
}

#containerStream {
  position: relative;
  height: 100%;
}

#badge {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px 30px 5px 30px;
  transform: translate(-50%, -50%);
  background-color: var(--main-color);
  border-radius: 15px;
  border: 2px solid var(--background-color);
  cursor: pointer;
  transition-delay: 0.5s;
}

p {
  color: var(--background-color);
  user-select: none;
}

.scan-enter-from, .scan-leave-to {
  opacity: 0;
}

.scan-enter-active, .scan-leave-active {
  transition: opacity 1s ease;
}
</style>