<template>
  <div v-if="show" class="dialog-overlay">
    <div class="dialog-content">
      <h3>إدخال عدد المرافقين</h3>
      
      <div class="number-control">
        <button 
          @click="decreaseNumber" 
          class="control-btn"
          :disabled="companions <= 0"
        >-</button>
        
        <input
          type="number"
          v-model="companions"
          min="0"
          class="companion-input"
          placeholder="عدد المرافقين"
        />
        
        <button 
          @click="increaseNumber" 
          class="control-btn"
        >+</button>
      </div>

      <div class="dialog-buttons">
        <button @click="confirm" class="confirm-btn">تأكيد</button>
        <button @click="cancel" class="cancel-btn">إلغاء</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanionDialog',
  
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  
  data() {
    return {
      companions: 0
    }
  },
  
  methods: {
    increaseNumber() {
      this.companions++;
    },
    
    decreaseNumber() {
      if (this.companions > 0) {
        this.companions--;
      }
    },
    
    confirm() {
      this.$emit('confirm', this.companions);
      this.companions = 0;
    },
    
    cancel() {
      this.$emit('cancel');
      this.companions = 0;
    }
  }
}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: flex-end; /* يضع المربع في الأسفل */
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 20px 20px 0 0; /* تدوير الزوايا العلوية فقط */
  text-align: center;
  width: 100%;
}

.number-control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.control-btn {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #f0f0f0;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.control-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.companion-input {
  margin: 0;
  padding: 8px;
  font-size: 24px;
  width: 100px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  -moz-appearance: textfield; /* يخفي أزرار التحكم الافتراضية في فايرفوكس */
}

/* يخفي أزرار التحكم الافتراضية في كروم وسفاري */
.companion-input::-webkit-outer-spin-button,
.companion-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dialog-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.confirm-btn, .cancel-btn {
  padding: 12px 30px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  min-width: 120px;
}

.confirm-btn {
  background-color: #4CAF50;
  color: white;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

/* تحسينات لمسية للهواتف */
@media (max-width: 768px) {
  .dialog-content {
    padding: 25px 20px 40px; /* زيادة التباعد السفلي */
  }
  
  .control-btn {
    width: 60px; /* أزرار أكبر للمس */
    height: 60px;
    font-size: 28px;
  }
  
  .companion-input {
    font-size: 28px;
    width: 120px;
  }
  
  .confirm-btn, .cancel-btn {
    padding: 15px 30px;
    font-size: 18px;
  }
}
</style>